<template>
  <div class="home">
    <van-nav-bar title="客户" left-arrow id="reset" left-text="返回" @click-left="onClickLeft"/>
    <div class="top">
      <div class="t-1">
        <span class="txt">排序</span>
        <img src="../static/客户/paixu.png" alt="">
      </div>
  <!--    <div class="t-1">
        <span class="txt">筛选</span>
        <img src="../static/客户/shaixuan.png" alt="">
      </div> -->

    </div>
	
	
	
    <div  v-for="(item,index) in list" :key="index" class="item" @click=routeTo(item.customer_id)>
      <div class="it">
        <img src="../static/客户/touxiang2.png" alt="" class="item-img">
      </div>
      <div class="it0">
        <div class="it1">
          <p>{{item.name}}</p>
          <img src="../static/客户/fanhuikaobei.png" alt="">
        </div>
        <div class="it2">
          <p>手机号：{{item.crm_okpkzz}}</p>
          <!-- <p :formatter="dateFormat">{{dateFormat(item.create_time,0)}}</p> -->
        </div>

      </div>
    </div>
  </div>
</template>

<script>
	import moment from 'moment';

export default {
  data() {
    return {
      active: 0,
	  list:{},
	  
    };
  },created () {
    this.getHistoryData()
  },
methods:{
	routeTo(customer_id)
	{
 		console.log(customer_id);
		this.$router.push({
		  path: `/kehuxiangqing?id=`+customer_id,
		})
		
	},
	dateFormat(row, column) {
      var date = row[column.property];
      if (date == undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD");
    },
 
    onClickLeft() {
    this.$router.go(-1);//返回上一层
    },
 
 getHistoryData(){
 	let param = new URLSearchParams();
 	
 	 param.append("token", this.$store.getters. getDemoValue);
 	
 	this.axios.post('/vue.php?m=customer&a=index',param).then(res => {
 		if (res.data.status === -1) {

 			setTimeout(()=>{
 				this.$router.push({
 				  path:"/"
 				})
 			},1000)
 		}
 		if (res.data.status === 1) {
 			
			this.list = res.data.list;
 			console.log(this.list);
 		
 		}
 		console.log(res);
 	}).catch(function (error){
 		console.log(error);
 	});
 }
 
},

};
</script>

<style lang="scss" scoped>
.top {
  margin: 15px 0;
  display: flex;
  .t-1 {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-left: 5px;
      width: 16px;
      height: 14px;
    }
  }
}
.item {
  width: 345px;
  height: 78px;
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(44, 49, 140, 0.11);
  border-radius: 5px;
  margin: 15px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  .it {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .item-img {
    width: 37px;
    height: 38px;
  }
  p {
    margin: 0;
  }
  .it0 {
    width: 75%;
  }
  .it2 {
      margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    align-items: center;
    height: 20px;
    p {
      // width: 85px;
      height: 13px;
      font-size: 14px;
      font-weight: normal;
      color: #8d8d8d;
    }
  }
  .it1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      height: 18px;
      font-size: 19px;
      font-weight: normal;
      color: #373c40;
    }
    img {
      width: 10px;
      height: 17px;
    }
  }
}
.home {
  height: 100vh;
  background: #f2f2f2;
  padding-bottom: 20px;
}
#reset ::v-deep .van-nav-bar__title {
  color: #ffffff !important;
}
.van-nav-bar {
  background: #1288f4;
}
#reset ::v-deep .van-icon.van-icon-arrow-left.van-nav-bar__arrow {
  color: white !important;
}
</style>